import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Head from "../components/head"
import Layout from "../components/layout"

import * as articleStyles from "../styles/pages/articles.module.scss"

const BlogPage = () => {
  const data = useStaticQuery<QueryType>(Query)
  const edges: EdgeType[] = data.allMarkdownRemark.edges

  return (
    <div>
      <Layout>
        <Head title="Articles" />
        <h1 className={articleStyles.heading}>All Articles</h1>
        <ol>
          {edges.map((edge: EdgeType) => {
            const frontmatter = edge.node.frontmatter
            const slug = `${edge.node.fields.slug}`
            return (
              <Link to={slug}>
                <div className={articleStyles.container}>
                  <li>
                    <h2>{frontmatter.title}</h2>
                    <p className={articleStyles.dateStyle}>
                      Published on {frontmatter.date}
                    </p>
                    <p>{frontmatter.shortDescription}</p>
                  </li>
                </div>
              </Link>
            )
          })}
        </ol>
      </Layout>
    </div>
  )
}

export default BlogPage

export const Query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "article" } } }) {
      edges {
        node {
          frontmatter {
            title
            date
            shortDescription
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

type EdgeType = {
  node: {
    frontmatter: {
      title: string
      date: string
      shortDescription: string
    }
    fields: {
      slug: string
    }
  }
}

export type QueryType = {
  allMarkdownRemark: {
    edges: EdgeType[]
  }
}
